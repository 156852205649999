<template>
  <div :id="anchorName" class="card-style1">
    <h2 v-if="titleStyle == 1 && name" class="dynamic-main-title tmw-40-32-15">
      {{ name }}
    </h2>
    <h2 v-else-if="titleStyle == 2 && name" class="dynamic-sub-title">
      {{ name }}
    </h2>
    <v-container v-if="hasContainer" class="inner-container inner-container--content">
      <CardColumn v-for="(item, i) in items" :key="i" :item="item" />
    </v-container>
    <template v-else>
      <CardColumn v-for="(item, i) in items" :key="i" :item="item" />
    </template>
  </div>
</template>

<script>
import listMixins from "./list.js";
import CardColumn from "@/components/dynamic/card/item/cardColumn.vue";
export default {
  mixins: [listMixins],
  props: {
    titleStyle: {
      type: [Number, String],
      default: 1,
    },
    hasContainer: {
      type: Boolean,
      default: true
    },
  },
  components: {
    CardColumn,
  },
};
</script>